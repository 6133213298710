import { useMutation } from '@apollo/react-hooks';
import type { FC } from 'react';
import React, { Fragment, useCallback, memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/side-navigation';
import { N500, N20, B50 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ConfluenceEdition } from '@confluence/change-edition';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import {
	SPACE_CALENDARS,
	SPACE_QUESTIONS,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	SPACE_PAGES,
	DATABASE_CUSTOM_OVERVIEW,
	SPACE_CALENDARS_SUBCALENDAR,
} from '@confluence/named-routes';
import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { usePageSpaceKey } from '@confluence/page-context';
import type { Route } from '@confluence/route';
import { SpaceSettingsSidebarLinks } from '@confluence/space-settings';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useIsBlogTreeUnderContentTreeFFEnabled } from '@confluence/blog-tree/entry-points/useIsBlogTreeUnderContentTreeFFEnabled';

import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceNavigationQuery } from './SpaceNavigationQuery.graphql';
import { AddSpaceLink } from './AddSpaceLink.experimentalgraphql';
import { BlogNavigation } from './BlogNavigation';
import { RemoveSpaceLink } from './RemoveSpaceLink.experimentalgraphql';
import { useSidebarConfigurationListener } from './useSidebarConfigurationListener';
import {
	ANALYTICS_KEY,
	BLOG_KEY,
	CALENDARS_KEY,
	QUESTIONS_KEY,
	SPACE_SETTINGS_KEY,
	DATABASES_KEY,
	MIGRATED_DATABASES_KEY,
	AUTOMATION_KEY,
	BULK_TOOLS_KEY,
} from './webItemCompleteKeys';
import { DatabasesNavigation } from './DatabasesNavigation';
import { AnalyticsNavigation } from './AnalyticsNavigation';

const GridIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GridIcon" */ './img/GridIcon')).GridIcon,
});
const QuestionsIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuestionIcon" */ './img/QuestionsIcon'))
			.QuestionsIcon,
});
const AutomationNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AutomationNavigation" */ './AutomationNavigation'))
			.AutomationNavigation,
});
const TeamCalendarNavigation = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TeamCalendarNavigation" */ './TeamCalendarNavigation'
			)
		).TeamCalendarNavigation,
});
const BulkToolsNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-BulkToolsNavigation" */ './BulkToolsNavigation'))
			.BulkToolsNavigation,
});

const i18nTooltips = defineMessages({
	allContentTooltip: {
		id: 'side-navigation.space-navigation.all-content.tooltip',
		defaultMessage: 'View all content in this space',
		description: 'Tooltip message on hover of All Content space link',
	},
	questionsTooltip: {
		id: 'side-navigation.space-navigation.questions.tooltip',
		defaultMessage: 'Ask and answer questions for this space',
		description: 'Tooltip message on hover of Questions space link',
	},
});

export const cssFn = ({ isSelected = false, isDatabaseIcon = false }) => ({
	padding: `0px ${token('space.050', '4px')} 0px ${token('space.100', '8px')}`,
	height: '36px',
	'min-height': '36px',
	'border-radius': '3px',
	'font-size': '14px',
	span: {
		columnGap: token('space.100', '8px'),
	},
	backgroundColor: isSelected ? token('color.background.selected', B50) : 'transparent',
	'&:hover': {
		backgroundColor: isSelected
			? token('color.background.selected.hovered', '#cce0ff')
			: token('color.background.neutral.subtle.hovered', N20),
	},
	'&, &:visited, &:active, &:focus': {
		color: isSelected ? token('color.text.selected', '#0c66e4') : token('color.text.subtle', N500),
	},
	textDecoration: 'none !important',
	'[data-item-elem-before=true]': {
		'margin-right': '0px',
		height: '24px',
		width: '24px',
		'img, span, svg': {
			height: isDatabaseIcon ? '18px' : '20px',
			width: isDatabaseIcon ? '18px' : '20px',
		},
	},
});

type SpaceLinksProps = {
	isSpaceAdmin: boolean;
	links: SpaceNavigationQuery_spaceSidebarLinks_main[];
	isSpaceSettingsScreen?: boolean;
	isMoreFeaturesEnabled?: boolean;
	isAdvancedFeatures?: boolean;
};

// If pathname is undefined or empty string, then the pathname || "" check will return "/" and match
// the route against "/", otherwise it will match the route against the pathname passed in
export const isRoute = (pathname: string | undefined, route: Route) =>
	Boolean(route.match(pathname || ''));

const isOnDatabasesRouteArgs = {
	selector: (routeName: string | undefined) => routeName === DATABASE_CUSTOM_OVERVIEW.name,
};
const isOnSpacePagesRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_PAGES.name,
};
const isOnBlogRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === VIEW_BLOG.name || routeName === VIEW_BLOG_DATE_LEGACY.name,
};
const isOnSpaceCalendarsRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_CALENDARS.name || routeName === SPACE_CALENDARS_SUBCALENDAR.name,
};
const isOnSpaceQuestionsRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS.name,
};

export const SpaceLinks: FC<SpaceLinksProps> = memo(
	({
		isSpaceAdmin,
		links,
		isSpaceSettingsScreen = false,
		isMoreFeaturesEnabled = false,
		isAdvancedFeatures = false,
	}) => {
		const [spaceKey] = usePageSpaceKey();
		const routeDataRef = useRouteDataRef();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isLicensed, edition } = useSessionData();
		const isDatabaseMigrationCompleted = useBooleanFeatureFlag(
			'confluence.frontend.database.migration.completed',
		);
		const isBlogTreeUnderContentTreeFFEnabled = useIsBlogTreeUnderContentTreeFFEnabled();

		const isEditionsCalendarsExperimentEnabled =
			FeatureGates.getExperimentValue<string>(
				'cc_editions_move_up_calendars',
				'cohort',
				'not-enrolled',
			) === 'test';
		const isPremiumEdition = edition === ConfluenceEdition.PREMIUM;

		const databasesRoute = DATABASE_CUSTOM_OVERVIEW;

		const [addSpaceLink] = useMutation(AddSpaceLink, {
			refetchQueries: [
				{
					query: SpaceNavigationQuery,
					variables: { spaceKey, isLicensed },
				},
			],
		});
		const [removeSpaceLink] = useMutation(RemoveSpaceLink, {
			refetchQueries: [
				{
					query: SpaceNavigationQuery,
					variables: { spaceKey, isLicensed },
				},
			],
		});

		useSidebarConfigurationListener(addSpaceLink, removeSpaceLink);

		const sendItemClickedAnalytics = useCallback(
			(itemId: any, isSidebarCollapsed: boolean, navdexPointType: any, additionalAttributes = {}) =>
				() => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'clicked',
							actionSubject: 'navigationItem',
							actionSubjectId: 'spaceNavigationItem',
							source: 'containerNavigation',
							attributes: {
								navigationLayer: 'container',
								isExpanded: !isSidebarCollapsed,
								itemId,
								selectedItemPageContext: routeDataRef.current?.routeName,
								navVersion: '3',
								navdexPointType,
								...additionalAttributes,
							},
						},
					}).fire();
				},
			[createAnalyticsEvent, routeDataRef],
		);
		const isOnSpacePagesRoute = useRouteName(isOnSpacePagesRouteArgs);
		const isOnBlogRoute = useRouteName(isOnBlogRouteArgs);
		const isOnDatabasesRoute = useRouteName(isOnDatabasesRouteArgs);
		const isOnSpaceCalendarsRoute = useRouteName(isOnSpaceCalendarsRouteArgs);
		const isOnSpaceQuestionsRoute = useRouteName(isOnSpaceQuestionsRouteArgs);

		// Please also update getPremiumFeaturesWebItemKeys() in SpaceNavigation if any web item conditions are updated
		const getBuiltInItemsMappingForAdvancedFeatures = useCallback(
			(isSidebarCollapsed: boolean, isAdvancedFeatures: boolean) =>
				isAdvancedFeatures
					? {
							...(isSpaceAdmin && {
								[AUTOMATION_KEY]: (
									<AutomationNavigation
										onClick={sendItemClickedAnalytics('automation', isSidebarCollapsed, undefined, {
											edition,
										})}
										isSpaceAdmin={isSpaceAdmin}
									/>
								),
							}),
							[CALENDARS_KEY]: (
								<TeamCalendarNavigation
									isSelected={isOnSpaceCalendarsRoute}
									onClick={sendItemClickedAnalytics('calendars', isSidebarCollapsed, undefined, {
										isPremiumEdition,
									})}
								/>
							),
							[ANALYTICS_KEY]: (
								<AnalyticsNavigation
									onClick={sendItemClickedAnalytics('analytics', isSidebarCollapsed, undefined)}
								/>
							),
							[QUESTIONS_KEY]: (
								<Tooltip
									position="top"
									content={<FormattedMessage {...i18nTooltips.questionsTooltip} />}
								>
									{(tooltipProps) => (
										<LinkItem
											// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
											cssFn={cssFn}
											key="questions"
											iconBefore={<QuestionsIcon isSelected={isOnSpaceQuestionsRoute} />}
											isSelected={isOnSpaceQuestionsRoute}
											href={SPACE_QUESTIONS.toUrl({ spaceKey })}
											{...tooltipProps}
											onClick={sendItemClickedAnalytics('questions', isSidebarCollapsed, undefined)}
										>
											<FormattedMessage
												id="side-navigation.container.questions"
												defaultMessage="Questions"
												description="Questions Menu Item"
											/>
										</LinkItem>
									)}
								</Tooltip>
							),
							[DATABASES_KEY]: (
								<DatabasesNavigation
									isSelected={isOnDatabasesRoute}
									onClick={sendItemClickedAnalytics('databases', isSidebarCollapsed, undefined)}
									databasesRoute={databasesRoute}
								/>
							),
							...(isPremiumEdition &&
								isSpaceAdmin && {
									[BULK_TOOLS_KEY]: (
										<BulkToolsNavigation
											testId="bulk-tools-link"
											onClick={sendItemClickedAnalytics('bulkTools', isSidebarCollapsed, undefined)}
										/>
									),
								}),
						}
					: {},
			[
				databasesRoute,
				edition,
				isPremiumEdition,
				isSpaceAdmin,
				isOnDatabasesRoute,
				isOnSpaceCalendarsRoute,
				isOnSpaceQuestionsRoute,
				sendItemClickedAnalytics,
				spaceKey,
			],
		);

		const getBuiltInItemsMapping = useCallback(
			(isSidebarCollapsed: boolean) => ({
				[ANALYTICS_KEY]: (
					<AnalyticsNavigation
						onClick={sendItemClickedAnalytics('analytics', isSidebarCollapsed, undefined)}
					/>
				),
				[QUESTIONS_KEY]: (
					<Tooltip position="top" content={<FormattedMessage {...i18nTooltips.questionsTooltip} />}>
						{(tooltipProps) => (
							<LinkItem
								// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
								cssFn={cssFn}
								key="questions"
								iconBefore={<QuestionsIcon isSelected={isOnSpaceQuestionsRoute} />}
								isSelected={isOnSpaceQuestionsRoute}
								href={SPACE_QUESTIONS.toUrl({ spaceKey })}
								{...tooltipProps}
								onClick={sendItemClickedAnalytics('questions', isSidebarCollapsed, undefined)}
							>
								<FormattedMessage
									id="side-navigation.container.questions"
									defaultMessage="Questions"
									description="Questions Menu Item"
								/>
							</LinkItem>
						)}
					</Tooltip>
				),
				...(isSpaceAdmin && {
					[AUTOMATION_KEY]: (
						<AutomationNavigation
							onClick={sendItemClickedAnalytics('automation', isSidebarCollapsed, undefined, {
								edition,
							})}
							isSpaceAdmin={isSpaceAdmin}
						/>
					),
				}),
				[CALENDARS_KEY]: (
					<TeamCalendarNavigation
						isSelected={isOnSpaceCalendarsRoute}
						onClick={sendItemClickedAnalytics('calendars', isSidebarCollapsed, undefined, {
							isPremiumEdition,
						})}
					/>
				),
				[DATABASES_KEY]: (
					<DatabasesNavigation
						isSelected={isOnDatabasesRoute}
						onClick={sendItemClickedAnalytics('databases', isSidebarCollapsed, undefined)}
						databasesRoute={databasesRoute}
					/>
				),
				...(isPremiumEdition &&
					isSpaceAdmin && {
						[BULK_TOOLS_KEY]: (
							<BulkToolsNavigation
								testId="bulk-tools-link"
								onClick={sendItemClickedAnalytics('bulkTools', isSidebarCollapsed, undefined)}
							/>
						),
					}),
			}),
			[
				isSpaceAdmin,
				spaceKey,
				edition,
				sendItemClickedAnalytics,
				isOnDatabasesRoute,
				isOnSpaceCalendarsRoute,
				isOnSpaceQuestionsRoute,
				databasesRoute,
				isPremiumEdition,
			],
		);

		const renderSpaceLinks = useCallback(
			(isSidebarCollapsed: any) => {
				const builtInItems = isMoreFeaturesEnabled
					? getBuiltInItemsMappingForAdvancedFeatures(isSidebarCollapsed, isAdvancedFeatures)
					: getBuiltInItemsMapping(isSidebarCollapsed);

				//check the links to see if blogs is present and return an inverted boolean
				const blogsHidden =
					links.filter(
						//@ts-ignore
						({ webItemCompleteKey, hidden }) => {
							return webItemCompleteKey === BLOG_KEY && hidden === true;
						},
					).length > 0;

				const migratedDatabasesVisible = links.some(({ webItemCompleteKey, hidden }) => {
					return webItemCompleteKey === MIGRATED_DATABASES_KEY && hidden === false;
				});

				// Experiment to move up Calendars link to below Automation
				const automationWebItem = links.find((link) => link.title === 'Automation');
				const updatedLinks =
					isEditionsCalendarsExperimentEnabled && automationWebItem
						? links.map((link) =>
								link.title === 'Calendars'
									? { ...link, position: automationWebItem.position + 1 }
									: link,
							)
						: links;
				return (
					<Fragment>
						{!(isMoreFeaturesEnabled && isAdvancedFeatures) && (
							<Tooltip
								position="top"
								content={<FormattedMessage {...i18nTooltips.allContentTooltip} />}
							>
								{(tooltipProps) => (
									<LinkItem
										// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
										cssFn={cssFn}
										key="all-content"
										iconBefore={<GridIcon isSelected={isOnSpacePagesRoute} />}
										isSelected={isOnSpacePagesRoute}
										href={SPACE_PAGES.toUrl({ spaceKey })}
										{...tooltipProps}
										onClick={sendItemClickedAnalytics('allContent', isSidebarCollapsed, undefined)}
									>
										<FormattedMessage
											id="side-navigation.container.all-content"
											defaultMessage="All content"
											description="Space Pages Button"
										/>
									</LinkItem>
								)}
							</Tooltip>
						)}
						{!isBlogTreeUnderContentTreeFFEnabled &&
							!(isMoreFeaturesEnabled && isAdvancedFeatures) &&
							!blogsHidden && (
								<BlogNavigation
									key="blogNavigation"
									isSelected={isOnBlogRoute}
									onClick={sendItemClickedAnalytics('blog', isSidebarCollapsed, undefined)}
								/>
							)}
						{updatedLinks
							//Depending on the type of space, space settings may be hidden.
							//Make sure space settings is always shown
							.filter((link): link is SpaceNavigationQuery_spaceSidebarLinks_main =>
								Boolean(link && (!link.hidden || link.webItemCompleteKey === SPACE_SETTINGS_KEY)),
							)
							.sort((a, b) => Number(a.position) - Number(b.position))
							.map(
								({ webItemCompleteKey }) =>
									(webItemCompleteKey && builtInItems[webItemCompleteKey]) ||
									/* Hide everything else */ null,
							)}
						{isDatabaseMigrationCompleted &&
							migratedDatabasesVisible &&
							((isMoreFeaturesEnabled && isAdvancedFeatures) || !isMoreFeaturesEnabled) && (
								<DatabasesNavigation
									isSelected={isOnDatabasesRoute}
									onClick={sendItemClickedAnalytics('databases', isSidebarCollapsed, undefined)}
									databasesRoute={databasesRoute}
								/>
							)}

						{!(isMoreFeaturesEnabled && isAdvancedFeatures) && (
							<ErrorBoundary attribution={Attribution.ADMIN_EXPERIENCE}>
								<SpaceSettingsSidebarLinks
									isSideNavRefreshAutomationEnabled={links.some(
										({ webItemCompleteKey, hidden }) =>
											webItemCompleteKey === AUTOMATION_KEY && hidden === false,
									)}
									spaceKey={spaceKey}
									isSpaceSettingsScreen={isSpaceSettingsScreen}
									cssFn={cssFn}
								/>
							</ErrorBoundary>
						)}
					</Fragment>
				);
			},
			[
				isEditionsCalendarsExperimentEnabled,
				isMoreFeaturesEnabled,
				getBuiltInItemsMappingForAdvancedFeatures,
				isAdvancedFeatures,
				getBuiltInItemsMapping,
				links,
				isOnSpacePagesRoute,
				isOnBlogRoute,
				isOnDatabasesRoute,
				sendItemClickedAnalytics,
				isDatabaseMigrationCompleted,
				databasesRoute,
				spaceKey,
				isSpaceSettingsScreen,
				isBlogTreeUnderContentTreeFFEnabled,
			],
		);

		return renderSpaceLinks(false);
	},
);
