/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

export enum SpaceSidebarLinkType {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  FORGE = "FORGE",
  PINNED_ATTACHMENT = "PINNED_ATTACHMENT",
  PINNED_BLOG_POST = "PINNED_BLOG_POST",
  PINNED_PAGE = "PINNED_PAGE",
  PINNED_SPACE = "PINNED_SPACE",
  PINNED_USER_INFO = "PINNED_USER_INFO",
  WEB_ITEM = "WEB_ITEM",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpaceNavigationQuery
// ====================================================

export interface SpaceNavigationQuery_user_confluence {
  permissionType: SitePermissionType | null;
}

export interface SpaceNavigationQuery_user {
  id: string | null;
  confluence: SpaceNavigationQuery_user_confluence | null;
}

export interface SpaceNavigationQuery_spaceSidebarLinks_main_icon {
  path: string;
  width: number | null;
  height: number | null;
  isDefault: boolean | null;
}

export interface SpaceNavigationQuery_spaceSidebarLinks_main {
  id: string;
  webItemKey: string | null;
  webItemCompleteKey: string | null;
  title: string | null;
  url: string | null;
  position: number;
  styleClass: string | null;
  icon: SpaceNavigationQuery_spaceSidebarLinks_main_icon | null;
  iconClass: string | null;
  hidden: boolean;
  canHide: boolean | null;
  tooltip: string | null;
  linkIdentifier: string | null;
  type: SpaceSidebarLinkType;
}

export interface SpaceNavigationQuery_spaceSidebarLinks_quick {
  id: string;
  url: string | null;
  position: number;
  title: string | null;
  styleClass: string | null;
}

export interface SpaceNavigationQuery_spaceSidebarLinks {
  main: (SpaceNavigationQuery_spaceSidebarLinks_main | null)[] | null;
  quick: (SpaceNavigationQuery_spaceSidebarLinks_quick | null)[] | null;
}

export interface SpaceNavigationQuery_space_icon {
  path: string;
}

export interface SpaceNavigationQuery_space_homepage {
  id: string | null;
}

export interface SpaceNavigationQuery_space_currentUser {
  isAdmin: boolean;
}

export interface SpaceNavigationQuery_space_operations {
  operation: string | null;
  targetType: string | null;
}

export interface SpaceNavigationQuery_space_history {
  createdDate: string | null;
}

export interface SpaceNavigationQuery_space {
  id: string | null;
  key: string | null;
  name: string | null;
  type: string | null;
  icon: SpaceNavigationQuery_space_icon | null;
  homepage: SpaceNavigationQuery_space_homepage | null;
  currentUser: SpaceNavigationQuery_space_currentUser;
  operations: (SpaceNavigationQuery_space_operations | null)[] | null;
  containsExternalCollaborators: boolean;
  history: SpaceNavigationQuery_space_history | null;
}

export interface SpaceNavigationQuery {
  user: SpaceNavigationQuery_user | null;
  spaceSidebarLinks: SpaceNavigationQuery_spaceSidebarLinks | null;
  space: SpaceNavigationQuery_space | null;
}

export interface SpaceNavigationQueryVariables {
  spaceKey?: string | null;
  isLicensed: boolean;
}
