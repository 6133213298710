import { useContext } from 'react';

import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

type HookType = {
	isRouteCheck?: boolean;
	isSpaceAdmin?: boolean;
};

export const useIsElevatedAutomationUpsellEnabled = ({
	isRouteCheck: shouldSkipSpaceAdminCheck = false,
	isSpaceAdmin = false,
}: HookType = {}) => {
	const { edition } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);

	// For free, EA upsell to all Free space admin users ( technically no space admin in Free as Free has no interaction with permissions )
	const isEnabledForFree = shouldSkipSpaceAdminCheck ? true : isSpaceAdmin;
	// For standard, if user is Site Admin + space admin users
	const isEnabledForStandard = shouldSkipSpaceAdminCheck
		? isSiteAdmin
		: isSpaceAdmin && isSiteAdmin;

	if (edition === ConfluenceEdition.PREMIUM) return false;

	// For eligibility check coming from route component, we only check editions and site admin and defer space admin check in upsell component renders because that piece of information is not available.
	return edition === ConfluenceEdition.STANDARD ? isEnabledForStandard : isEnabledForFree;
};
