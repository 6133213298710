import { useQuery } from '@apollo/react-hooks';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ConfluenceEdition } from '@confluence/change-edition';
import { isGraphQLError } from '@confluence/network';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';

import { AdvancedFeaturesInstanceIsInTrialQuery } from './AdvancedFeaturesInstanceIsInTrialQuery.graphl';
import type { AdvancedFeaturesInstanceIsInTrialQuery as AdvancedFeaturesInstanceIsInTrialQueryType } from './__types__/AdvancedFeaturesInstanceIsInTrialQuery';

type useIsRelocatingPremiumFeaturesExperimentEnabledType = {
	edition: ConfluenceEdition | null;
	isLoggedIn: boolean;
};

export const useIsRelocatingPremiumFeaturesExperimentEnabled = ({
	edition,
	isLoggedIn,
}: useIsRelocatingPremiumFeaturesExperimentEnabledType) => {
	// only target instances in standard or premium trials (including reverse trials)

	const {
		data: inTrialData,
		loading: inTrialLoading,
		error: inTrialError,
	} = useQuery<AdvancedFeaturesInstanceIsInTrialQueryType>(AdvancedFeaturesInstanceIsInTrialQuery, {
		skip: !isLoggedIn,
	});

	if (inTrialError && isGraphQLError(inTrialError) && !isErrorMarkedAsHandled(inTrialError)) {
		markErrorAsHandled(inTrialError);
		return false;
	}

	if (inTrialError) {
		return false;
	}

	const isStandardOrPremiumTrial =
		!inTrialLoading &&
		(edition === ConfluenceEdition.STANDARD || edition === ConfluenceEdition.PREMIUM) &&
		Boolean(
			inTrialData?.license?.trialEndDate, // In trial if there is a trial end date
		);

	return (
		isStandardOrPremiumTrial &&
		FeatureGates.getExperimentValue<'control' | 'experiment'>(
			'confluence_side_nav_more_features_section',
			'cohort',
			'control',
		) === 'experiment'
	);
};
