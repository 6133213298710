import { LoadableLazy } from '@confluence/loadable';

export const MigrateShortcutsChangeboarding = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-MigrateShortcutsChangeboarding" */ '../src/MigrateShortcutsChangeboarding'
			)
		).MigrateShortcutsChangeboarding,
});
